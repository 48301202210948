import * as _callBound2 from "call-bind/callBound";

var _callBound = "default" in _callBound2 ? _callBound2.default : _callBound2;

import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

var exports = {};
var callBound = _callBound;
var $boolToStr = callBound("Boolean.prototype.toString");
var $toString = callBound("Object.prototype.toString");

var tryBooleanObject = function booleanBrandCheck(value) {
  try {
    $boolToStr(value);
    return true;
  } catch (e) {
    return false;
  }
};

var boolClass = "[object Boolean]";

var hasToStringTag = _shams();

exports = function isBoolean(value) {
  if (typeof value === "boolean") {
    return true;
  }

  if (value === null || typeof value !== "object") {
    return false;
  }

  return hasToStringTag && Symbol.toStringTag in value ? tryBooleanObject(value) : $toString(value) === boolClass;
};

export default exports;